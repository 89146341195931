.App {
  text-align: center;
}
body{
  background-image: url(./Page\ Background.png) !important;
  background-size: cover;
  
}
@font-face {
  font-family: topbar;
  src: url(./SF\ Pixelate\ Bold.ttf);
}
@font-face {
  font-family: normalText;
  src: url(./SF\ Pixelate.ttf);
}
.mintContainer
{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  text-align: center;
}
.gif{
  width: 30vw;
  height: auto;
  margin-bottom: 2vh ;
}
.MuiButton-label {
font-size: 3.2vh !important;
/* width: 6.7vw !important; */

text-align: center !important;
padding-left: 2vw !important;
padding-right: 2vw !important;
}

.MuiButtonBase-root{
  background-color: #BF1909 !important;
  color: white ;
  font-family: normalText !important;
  border-radius: 2vh !important;
  border: 0.5vh solid white !important;
 

}
@media screen and (max-width:800px) {
  .gif{
    width: 60vw;
    height: auto;
  }
  body{
    background-image: url(./Page\ Background.png) !important;
    background-size: 100% 120%;
    
  }
}



.box{
  padding: 5vh;
}
.topbar{
  background-color: #BA4B36;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2vh;
  padding: 1vh 0 1vh 0;
}
.solana{
  text-align: center;
  width: 3.5vw;
  position: relative;
  top: 0.7vh;
  height: auto;
}
.solanaslot{
  font-family: topbar;
  font-size: 8vh;
  color: #F1C877;
  text-shadow: 0.2vw 0.2vw #FFFFFF;
}
.part1__right{
  text-align: center;

}
.part1__right p{
  text-align: center;
  font-size: 5.5vh;
  font-family: normalText;
  margin: 3vh;
  color: #BF1909;
}
.machine{
width: 36vw;
height: auto;
}
.bag1{
  position: absolute;
  top: 80vh;
  left: 85%;

width: 13vw;
height: auto;
}
.bag2{
  position: absolute;
  top: 86vh;
  left: 79%;

width: 13vw;
height: auto;
}
.bag3{
  position: absolute;
  top: 90vh;
  left: 86%;

width: 13vw;
height: auto;
}
.step1{
  text-align: left;
  font-size: 5vh;
  width: 50vw;
  font-family: normalText;
 
}
.step1 div{

  width: 80vw;

}
.slots{
  width: 5vw;
  height: auto;
  border: 1px solid black;
}
.quantity{
  font-size: 4vh;
  font-family: normalText;
}
.bottomLeft{
  text-align: left;
}
.bottomLeft div{
  text-align: left;
  font-size: 3vh;
  font-family: normalText;
  margin: 2vh 0 2vh 0;
}
.bottomRight{
  text-align: right;
}
.bottomRight span img{
  width: 5vw;
  height: auto;
}
.play{
  text-align: center;
  background-color: white;
  border: 2px solid red;
 padding: 1vh;
 border-radius: 20px;
}

@media screen and (max-width:800px) {
  .box{
    padding:3vh;
  }
  .solana{
    text-align: center;
    width: 8vw;
  top: 0.3vh;
    height: auto;
  }
  .solanaslot{
    font-family: topbar;
    font-size: 3vh;
    text-shadow: 2px 2px #FFFFFF;
  }
  .machine{
    width: 50vw;
    height: auto;
  }
  .part1__right p{
    text-align: center;
    font-size: 3vh;
    font-family: normalText;
    margin: 3vh;
    
  }
  .step1 div{
    font-size: 3vh;
    width: 100%;
    position: relative;
    z-index: 100;
  }
  .bottomLeft{
    text-align: center;
   
  }
  .bottomLeft div{
    text-align: center;
    font-size: 3vh;
    font-family: normalText;
  }
  .bottomRight{
    text-align: center;
  }
  .bottomRight span img{
    width: 10vw;
    height: auto;
  }
  .slots{
    width: 20vw;
    height: auto;
  }
  .quantity{
    font-size: 4vh;
    font-family: normalText;
  }
  .slot__body{
    margin: 5vh 0 5vh 0;
  }
  .bottom{
    margin-top: 5vh;
  }


  .bag1{
    position: absolute;
    top: 88vh;
    left:  78vw;
    right: 0;
  
  width: 20vw;
  height: auto;
  }
  .bag2{
    position: absolute;
    top: 93vh;
    left:  72vw;
  
  width: 20vw;
  height: auto;
  }
  .bag3{
    position: absolute;
    top: 95vh;
    left:  80vw;
  
  width: 20vw;
  height: auto;
  }
}


@media screen and (max-width:1024px) {
  .box{
    padding:3vh;
  }
  .solana{
    text-align: center;
    width: 4vw;
  
    height: auto;
  }
  .solanaslot{
    font-family: topbar;
    font-size: 3vh;
    text-shadow: 2px 2px #FFFFFF;
  }
  .machine{
    width: 50vw;
    height: auto;
  }
  .part1__right p{
    text-align: center;
    font-size: 3vh;
    font-family: normalText;
    margin: 3vh;
    
  }
  .step1 div{
    font-size: 3vh;
    width: 100%;
    position: relative;
    z-index: 100;
  }
  .bottomLeft{
    text-align: center;
   
  }
  .bottomLeft div{
    text-align: center;
    font-size: 3vh;
    font-family: normalText;
    margin: 2vh 0 2vh 0;
  }
  .bottomRight{
    text-align: center;
  }
  .bottomRight span img{
    width: 10vw;
    height: auto;
  }
  .slots{
    width: 20vw;
    height: auto;
  }
  .quantity{
    font-size: 4vh;
    font-family: normalText;
  }
  .slot__body{
    margin: 5vh 0 5vh 0;
  }
  .bottom{
    margin-top: 5vh;
  }


  .bag1{
    position: absolute;
    top: 88vh;
    left:  78vw;
    right: 0;
  
  width: 20vw;
  height: auto;
  }
  .bag2{
    position: absolute;
    top: 93vh;
    left:  72vw;
  
  width: 20vw;
  height: auto;
  }
  .bag3{
    position: absolute;
    top: 95vh;
    left:  80vw;
  
  width: 20vw;
  height: auto;
  }
}